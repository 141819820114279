import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, useLoaderData} from '@remix-run/react';
import {ProductSwimlane, FeaturedCollections, Hero} from '~/components';
import {
  MEDIA_FRAGMENT,
  PRODUCT_CARD_FRAGMENT,
  PRODUCT_FRAGMENT,
} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import {seoPayload} from '~/lib/seo.server';
import type {
  Collection,
  CollectionConnection,
  Metafield,
  ProductConnection,
} from '@shopify/hydrogen/storefront-api-types';
import {AnalyticsPageType} from '@shopify/hydrogen';
import {routeHeaders, CACHE_SHORT, CACHE_LONG} from '~/data/cache';
import type {LinksFunction} from '@shopify/remix-oxygen';

//components
import Module from 'components/modules/Module';

//domains
import {useHomeQuery} from 'domains/home';
import {getCollections} from 'domains/collections';
import {
  getSession,
} from '../../cookie.server';

import {
  getCountryCodeForSanityQuery,
  getLangagueCodeForSanityQuery,
  isTWSite,
} from '~/domains/sanity';
import type {HomeSanityData} from 'domains/home';
import type {
  SanityModuleSelectedCollections,
  SanityModuleSelectedProducts,
} from 'domains/modules';

const {LRUCache} = require('lru-cache');
const cache = new LRUCache({
  max: 500,
  ttl: 1000 * 60 * 60 * 24,
});
interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}

export interface CollectionHero {
  byline: Metafield;
  cta: Metafield;
  handle: string;
  heading: Metafield;
  height?: 'full';
  loading?: 'eager' | 'lazy';
  spread: Metafield;
  spreadSecondary: Metafield;
  top?: boolean;
}

let scripts: any = () => {
  // return [
  //   {
  //     src: 'https://loox.io/widget/4yxToKYeUo/loox.1635228300187.js?shop=helmetking-0001.myshopify.com',
  //     crossOrigin: 'anonymous',
  //     async: 'true',
  //   },
  // ];
};

export let handle = {scripts};

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css',
    },
  ];
};

export const headers = routeHeaders;

export async function loader({params, context, request}: LoaderArgs) {
  const {language, country} = context.storefront.i18n;
  let homeSanityData: HomeSanityData;
  // if (!cache.has('homeSanityData')) {
    homeSanityData = (await useHomeQuery({
      languageCode: getLangagueCodeForSanityQuery(language),
      countryCode: isTWSite(request.url) ? 'zh-tw' : 'zh-hk',
    })) as HomeSanityData;
  //   await cache.set('homeSanityData', homeSanityData);
  // } else {
  //   homeSanityData = await cache.get('homeSanityData');
  // }

  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const featuredProductsSection = homeSanityData?.modules.find(
    (m) => m._type === 'module.selectedShopifyProducts',
  ) as SanityModuleSelectedProducts;
  const featuredCollectionsSection = homeSanityData?.modules.find(
    (m) => m._type === 'custom.selectShopifyCollections',
  ) as SanityModuleSelectedCollections;
  const featuredProductIds =
    featuredProductsSection &&
    featuredProductsSection._type === 'module.selectedShopifyProducts' &&
    Array.isArray(featuredProductsSection?.modules) &&
    featuredProductsSection?.modules?.map((m) => m.product.store.gid as string);

  const featuredCollectionIds =
    featuredCollectionsSection &&
    featuredCollectionsSection._type === 'custom.selectShopifyCollections' &&
    Array.isArray(featuredCollectionsSection.shopify_collections) &&
    featuredCollectionsSection?.shopify_collections?.map(
      (c) => c.shopify_collection.store.gid,
    );

  // const {shop, hero} = await context.storefront.query<{
  //   hero: CollectionHero;
  //   shop: HomeSeoData;
  // }>(HOMEPAGE_SEO_QUERY, {
  //   variables: {handle: 'freestyle'},
  // });

  let featuredProducts;
  let featuredCollections;

  if (featuredProductIds) {
    try {
      const {products} = await context.storefront.query<{
        products: ProductConnection['nodes'];
      }>(
        `#graphql
      ${PRODUCT_FRAGMENT}
      query homeFeaturedProducts($country: CountryCode, $language: LanguageCode, $gids: [ID!]!)
      @inContext(country: $country, language: $language) {
        products: nodes(ids: $gids) {
          ...on Product{
            ...ProductMain
          }
        }

      }
    `,
        {
          variables: {
            country: isTWSite(request.url) ? 'TW' : 'HK',
            language,
            gids: featuredProductIds ?? [''],
          },
        },
      );
      featuredProducts = products;
    } catch (e) {
      console.log(e);
    }
  }

  if (featuredCollectionIds) {
    const session = await getSession(request.headers.get('Cookie'));
    const previous_lang = await session.get('previous-lang');
    const current_lang = params.lang ?? 'zh_tw';
    const is_lang_changed = previous_lang !== current_lang;

    if (cache.has('featuredCollections') && !is_lang_changed) {
      featuredCollections = await cache.get('featuredCollections');
    } else {
      const [collections, getCollectionsError] = await getCollections({
        collectionIds: featuredCollectionIds ? featuredCollectionIds : [''],
        context,
      });
      featuredCollections = collections as Collection[] | null;
      await cache.set('featuredCollections', featuredCollections);
    }
  }

  // const seo = seoPayload.home();

  return defer(
    {
      home: homeSanityData,
      featuredProducts,
      featuredCollections,
      analytics: {
        pageType: AnalyticsPageType.home,
      },
      // seo,
      seo: {
        title: homeSanityData?.seo?.title,
        description: homeSanityData?.seo?.description,
        robots: {
          noIndex: false,
          noFollow: false,
        },
        jsonLd: {
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Home page',
        },
      },
      // shop,
      // primaryHero: hero,
      // These different queries are separated to illustrate how 3rd party content
      // fetching can be optimized for both above and below the fold.
      // featuredProducts: context.storefront.query<{
      //   products: ProductConnection;
      // }>(HOMEPAGE_FEATURED_PRODUCTS_QUERY, {
      //   variables: {
      //     /**
      //      * Country and language properties are automatically injected
      //      * into all queries. Passing them is unnecessary unless you
      //      * want to override them from the following default:
      //      */
      //     country,
      //     language,
      //   },
      // }),
      // secondaryHero: context.storefront.query<{hero: CollectionHero}>(
      //   COLLECTION_HERO_QUERY,
      //   {
      //     variables: {
      //       handle: 'backcountry',
      //       country,
      //       language,
      //     },
      //   },
      // ),
      // featuredCollections: context.storefront.query<{
      //   collections: CollectionConnection;
      // }>(FEATURED_COLLECTIONS_QUERY, {
      //   variables: {
      //     country,
      //     language,
      //   },
      // }),
      // tertiaryHero: context.storefront.query<{hero: CollectionHero}>(
      //   COLLECTION_HERO_QUERY,
      //   {
      //     variables: {
      //       handle: 'winter-2022',
      //       country,
      //       language,
      //     },
      //   },
      // ),
    },
    {
      headers: {
        'Cache-Control': CACHE_SHORT,
        // 'Cache-Control': CACHE_LONG,
      },
    },
  );
}

export default function Homepage() {
  const {
    // primaryHero,
    // secondaryHero,
    // tertiaryHero,
    // featuredCollections,
    // featuredProducts,
    home,
  } = useLoaderData<typeof loader>();

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  return (
    <>
      {home.modules.map((module, index) => (
        <Module key={module._key} module={module} />
      ))}
      {/* {primaryHero && (
        <Hero {...primaryHero} height="full" top loading="eager" />
      )}

      {featuredProducts && (
        <Suspense>
          <Await resolve={featuredProducts}>
            {({products}) => {
              if (!products?.nodes) return <></>;
              return (
                <ProductSwimlane
                  products={products.nodes}
                  title="Featured Products"
                  count={4}
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {secondaryHero && (
        <Suspense fallback={<Hero {...skeletons[1]} />}>
          <Await resolve={secondaryHero}>
            {({hero}) => {
              if (!hero) return <></>;
              return <Hero {...hero} />;
            }}
          </Await>
        </Suspense>
      )}

      {featuredCollections && (
        <Suspense>
          <Await resolve={featuredCollections}>
            {({collections}) => {
              if (!collections?.nodes) return <></>;
              return (
                <FeaturedCollections
                  collections={collections.nodes}
                  title="Collections"
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {tertiaryHero && (
        <Suspense fallback={<Hero {...skeletons[2]} />}>
          <Await resolve={tertiaryHero}>
            {({hero}) => {
              if (!hero) return <></>;
              return <Hero {...hero} />;
            }}
          </Await>
        </Suspense>
      )} */}
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  ${MEDIA_FRAGMENT}
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
`;

const COLLECTION_HERO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
